import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/MdxPage.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Version 1, updated September 2021.`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "/legal/website-terms"
          }}>{`Website Terms`}</a></p>
        <p parentName="li">{`Terms and conditions governing the use of our web site.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "/legal/training-terms"
          }}>{`Training Terms`}</a></p>
        <p parentName="li">{`Terms and conditions governing our courses, workshops, and training materials.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "/legal/privacy-policy"
          }}>{`Privacy Policy`}</a></p>
        <p parentName="li">{`What personal information we collect about you, and what we use it for.`}</p>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      